<template>
    <div class="loading">
        <kds-loading-spinner :inverse-colors="spinnerColor === 'white'" show inline size="m"></kds-loading-spinner>
    </div>
</template>

<script>
export default {
    name: 'LscLoadingSpinner',
    props: {
        spinnerColor: String
    },
};
</script>

<style scoped>
.loading {
    display: flex;
    justify-content: space-around;
    align-content: space-around;
    align-items: center;
}
</style>
