const axios = require('axios');
const qs = require('qs');
const { serverUrl } = require('./../config/config.js');
const { store } = require('./globalStore'); 


async function dfRq(configNew, headersNew, dataNew, accessTokenActive, progressId) {

    //add url when url path is used
    if (configNew.urlPath) {
        configNew.url = `${serverUrl}${configNew.urlPath}`;
        delete configNew.urlPath;
    }

    let access_token = '';

    if (accessTokenActive) {
        try {
            access_token = localStorage.getItem("kc_token");
        } catch (error) {
            console.log("access_token error");
        }
    }

    let data = qs.stringify(dataNew);

    let configDefault = {
        method: "get",
        url: "",
        headers: {
            Authorization:
                `Bearer ${access_token}`,
            "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
        onDownloadProgress: progressEvent => {
            if (progressId) {
                const total = parseFloat(progressEvent.total);
                const current = progressEvent.loaded;
                let percentCompleted = Math.floor(current / total * 100);
                store.setProgress(progressId, percentCompleted);
            }
        }
    };

    let config = { ...configDefault, ...configNew };
    config.headers = { ...config.headers, ...headersNew };

    return axios(config);
}

async function fileUploadRequest(urlPath, file, progEv) {

    const url = `${serverUrl}${urlPath}`;

    let access_token = '';

    try {
        access_token = localStorage.getItem("kc_token");
    } catch (error) {
        console.log("access_token error");
    }

    let formData = new FormData();
    formData.append("file", file);

    return axios({
        method: "post",
        url,
        headers: {
            Authorization:
                `Bearer ${access_token}`,
        },
        data: formData,
        onUploadProgress: ProgressEvent => {
            progEv(ProgressEvent);
        }
    });
}

module.exports = {
    dfRq,
    fileUploadRequest
};