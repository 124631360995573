const basePath =  location.host ? `${location.protocol}//${location.hostname}` : "https://lion.koerber.com";

const basePathWithPort = location.port 
                            && location.port.length > 0 
                            && location.port != "80" 
                            && location.port != "443"                            
                            ? `${basePath}:${location.port}` : `${basePath}`;

const serverUrl = basePathWithPort;
const pictureUploadsUrl = `${basePathWithPort}/uploads`;

const keycloakRealmName = 'lion-service-center'; // todo get from server
const keycloakClientId = 'lsc-client'; // todo get from server
const keycloakAuthUrl = `${basePath}/auth`;  // todo get from server // todo how to generate??
const keycloakRealmUrl = `${keycloakAuthUrl}/realms/${keycloakRealmName}`;

const keycloakConfig = {
    redirectUrl: `${basePath}/app/login-pending`, // not in use
    accountLogin: `${keycloakRealmUrl}/protocol/openid-connect/auth?client_id=${keycloakClientId}&response_type=code&state=`, // not in use
    accountConfigUrl: `${keycloakRealmUrl}/account/#/personal-info`
}

// resource client - this client manages the server resources
const resourceClient = 'lsc-server'; // todo get from server

const keycloakInitOptions = {
    url: keycloakAuthUrl,
    realm: keycloakRealmName,
    clientId: keycloakClientId,
    onLoad: 'login-required'
};

module.exports = {
    serverUrl,
    keycloakInitOptions,
    pictureUploadsUrl,
    keycloakConfig,
    resourceClient
};