<template>
    <div class="login">
        <div class="logo-container" id="logo-container">
            <img
                alt="Vue logo"
                src="@/assets/koerber_logo_rgb_white.0763d983.svg"
            />
        </div>
        <LscLogin />
    </div>
</template>

<script>
// @ is an alias to /src
import LscLogin from '@/components/LscStructure/LscLogin.vue';

export default {
    name: 'Login',
    components: {
        LscLogin
    },
    mounted: function () {
        setTimeout(() => this.animateLogo(), 300);
    },
    methods: {
        animateLogo() {
            document.querySelector('#logo-container').classList.add('active');
        }
    }
};
</script>

<style scoped>
.login {
    background-color: var(--kds-color-acce2);
    height: 100%;
}

.logo-container {
    background-color: var(--kds-color-acce2);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: 1s;
}

.logo-container.active {
    height: 30%;
}

img {
    width: 20%;
    padding: 2rem;
    border-radius: 10px;
}
</style>
