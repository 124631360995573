import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import LscFrontpage from '@/views/LscFrontpage.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import LscLoginPending from '@/views/LscLoginPending.vue'

const routes = [
    {
        path: '/',
        name: 'Initial Login',
        component: LscFrontpage
    },
    {
        path: '/app/login',
        name: 'Login',
        component: LscFrontpage
    },
    {
        path: '/app/login2',
        name: 'LscFullLogin',
        component: Login
    },
    {
        path: '/app/login-pending',
        name: 'LscLoginPending',
        component: LscLoginPending
    },
    {
        path: '/app/lsc-home/:nodeId?/:tabName?/:elementId?',
        name: 'Lion Service Portal',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(
            // webpackChunkName: "about" 
            '@/views/LscHome.vue')
    },
    { path: '/:pathMatch(.*)*', component: PageNotFound },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
