<template>
    <div>
        <router-view />
    </div>
</template>

<style>
html,
body,
#app,
#app > div {
    padding: 0;
    margin: 0;
    height: 100%;
}

.centered {
    display: flex;
    justify-content: center;
}

.hidden {
    display: none;
}

input[type="date"] {
    width: 99%;
    margin: 4px 0px 0px;
    padding: 4px;
    border: var(--kds-input-border-width,1px) solid var(--kds-color-atmo5);
    background: rgba(0, 0, 0, 0);
    color: var(--kds-color-acce1);
    font: inherit;
    letter-spacing: inherit;
    flex-grow: 1;
}

input[type="date"]:hover {
    border: var(--kds-input-border-width,1px) solid var(--kds-color-acce2);
}

</style>
