<template>
    <div class="page-not-found">
        <div>
            <div class="logo-container" id="logo-container">
                <img
                    alt="Vue logo"
                    src="@/assets/koerber_logo_rgb_white.0763d983.svg"
                />
            </div>
            <div class="message-container cnt">
                <h1>Lion Service Center</h1>
            </div>

            <LscNoContent v-bind:dataArray="[]">
            <template v-slot:header-all><p>404</p></template>
            <template v-slot:content-all
                ><p>Your requested resource was not found</p></template
            >
            <template v-slot:action-all
                > <a class="redirect" @click="() => {router.push({ path: '/app/login' });}" v-if="globalStore.username === 'LOGGED OUT'">To Login</a>
                <a class="redirect" @click="() => {router.push({ path: '/app/lsc-home' });}" v-if="globalStore.username !== 'LOGGED OUT'">To Main Page</a></template
            >
        </LscNoContent>
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import router from '@/router/index';
import userHandler from '@/lib/userHandler';
import LscNoContent from '@/components/LscStructure/LscNoContent';

export default {
    name: 'PageNotFound',
    data() {
        return {
            globalStore: store.state,
            router: router
        };
    },
    components: {LscNoContent},
    created: function () {
        this.getUserInfo();
    },
    methods: {
                getUserInfo() {
            userHandler.getCurrentUser().then((user) => {
                store.setUser(user.data);
            });
        },
    }
};
</script>

<style scoped>
.page-not-found {
    background-color: var(--kds-color-acce2);
    height: 100%;
    color: var(--kds-color-atmo1);
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}

h1, p, a {
    color: var(--kds-color-atmo1);
}

.page-not-found > div {
    max-width: 800px;
}

.logo-container {
    background-color: var(--kds-color-acce2);
    display: flex;
    align-items: center;
    justify-content: space-around;
}

img {
    width: 20%;
    padding: 2rem;
    border-radius: 10px;
}

.message-container {
    display: flex;
    align-items: center;
    justify-content: left;
}

.message-container.cnt {
    justify-content: space-evenly;
    margin-bottom: 6rem;
}

 a.redirect {
    text-decoration: underline;
    cursor: pointer;
}

</style>
