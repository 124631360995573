<template>
    <div
        class="lsc-no-content"
        v-bind:class="{ hidden: dataArray && dataArray.length != 0 }"
    >
        <div class="lsc-no-content-info">
            <div class="info-icon">
                <span class="material-icons-outlined kds-m-2"> info </span>
            </div>

            <div class="info-header header kds-h5">
                <span v-bind:class="{ hidden: !globalState.isAdmin }">
                    <slot name="header-admin"></slot>
                </span>
                <span v-bind:class="{ hidden: globalState.isAdmin }">
                    <slot name="header-user"></slot>
                </span>
                <slot name="header-all"></slot>
            </div>
            <div class="info-content content">
                <div class="kds-spacer-mx-6"></div>

                <span v-bind:class="{ hidden: !globalState.isAdmin }">
                    <slot name="content-admin"></slot>
                </span>
                <span v-bind:class="{ hidden: globalState.isAdmin }">
                    <slot name="content-user"></slot>
                </span>
                <slot name="content-all"></slot>
            </div>
            <div class="info-action action">
                <span v-bind:class="{ hidden: !globalState.isAdmin }">
                    <slot name="action-admin"></slot>
                </span>
                <span v-bind:class="{ hidden: globalState.isAdmin }">
                    <slot name="action-user"></slot>
                </span>
                <slot name="action-all"></slot>
            </div>
        </div>
    </div>
</template>


<script>
import { store } from '@/lib/globalStore';

export default {
    components: {},
    data() {
        return {
            globalState: store.state,
            isOpen: false
        };
    },
    name: 'LscPopup',
    props: {
        dataArray: Object
    },
    methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header > span.hidden,
.content > span.hidden,
.action > span.hidden {
    display: none;
}

.lsc-no-content {
    display: flex;
    justify-content: space-around;
    margin: 1rem;
}

.lsc-no-content.hidden {
    display: none;
}

.lsc-no-content-info {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'info-icon info-header'
        '. info-content'
        '. info-action';
}

.info-icon {
    grid-area: info-icon;
}

.info-header {
    grid-area: info-header;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.info-content {
    grid-area: info-content;
    text-align: left;
}

.info-action {
    grid-area: info-action;
    display: flex;
    justify-content: flex-start;
}
</style>
