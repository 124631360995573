import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Keycloak from 'keycloak-js';
import { serverUrl } from './config/config';
import store from './lib/globalStore';

import 'material-icons/iconfont/outlined.css';

import 'koerber-design-system-web/css/bundle.css';
import 'koerber-design-system-web/bundle';

import mitt from 'mitt';
const emitter = mitt();

let keycloak = null;

const app = createApp(App).use(router);

fetch(`${serverUrl}/pub/frontend/config`).then(res => {return res.json()}).then(data => {
    store.store.setKeycloakInitOptions({
        url: data.keycloakAuthUrl,
        clientId: data.keycloakClientId,
        realm: data.keycloakRealmName
    });
    store.store.setResourceClient(data.resourceClient);
    const keycloakInitOptions = store.store.state.keycloakInitOptions;

    try {
        keycloak = new Keycloak(keycloakInitOptions);
    } catch (error) {
        console.log(error);        
    }

    app.config.globalProperties.emitter = emitter;
    app.config.globalProperties.keycloak = keycloak;
    document.title = "Lion Service Center";
    app.mount('#app');

    // check token and eventually init keycloak
    if (parseInt(localStorage.getItem("kc_refreshToken_expire")) > new Date().getTime() / 1000) {
        // refresh token is valid
        const token = localStorage.getItem('kc_token');
        const refreshToken = localStorage.getItem('kc_refreshToken');
        // pass to keycloak init
        keycloak.init({ onLoad: keycloakInitOptions.onLoad, token, refreshToken }).then(
            (authenticated) => {

                console.log("authenticated: ", authenticated);

                // success
                localStorage.setItem('kc_token', keycloak.token);
                localStorage.setItem('kc_refreshToken', keycloak.refreshToken);
                localStorage.setItem('kc_refreshToken_expire', keycloak.refreshTokenParsed.exp);

                keycloak.loadUserProfile().then((res) => {
                    // console.log(res);
                    store.store.setUser(res);
                });
            });
    }

    setInterval(() => {
        if (keycloak.authenticated) {
            keycloak.updateToken(70).then((refreshed) => {
                if (refreshed) {
                    // console.log('Token refreshed' + refreshed);

                    localStorage.setItem('kc_token', keycloak.token);
                    localStorage.setItem('kc_refreshToken', keycloak.refreshToken);
                    localStorage.setItem('kc_refreshToken_expire', keycloak.refreshTokenParsed.exp);
                } 
                /* else {
                    console.log('Token not refreshed, valid for ' 
                        + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                } */
            }).catch(() => {
                console.error('Failed to refresh token')
            });
        }
    }, 20000);
}).catch((err) => {
    console.error(err);
    console.error("Server Not Available");
    document.write("Maintenance - waiting to connect to server - come back in a few minutes")
})