const { dfRq } = require('./endpointConnector');

function getCurrentUser() {

    return dfRq({
        method: "get",
        urlPath: `/user/current`
    }, {}, {}, true);

}

module.exports = {
    getCurrentUser
};