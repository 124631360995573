<template>
    <div class="login-pending">
        <div>
            <div class="logo-container" id="logo-container">
                <img
                    alt="Vue logo"
                    src="@/assets/koerber_logo_rgb_white.0763d983.svg"
                />
            </div>
            <div class="message-container cnt">
                <h1>Lion Service Center</h1>
            </div>
            <p>
                {{
                    isOK
                        ? 'Everything is ok, you will be redirected in a few secounds'
                        : 'you will be redirected in a few secounds - if this takes to long, contact the lion support'
                }}
            </p>

            <LscLoadingSpinner v-bind:spinnerColor="'white'" class="spinner" />

            <a
                class="redirect"
                @click="
                    () => {
                        router.push({ path: '/app/login' });
                    }
                "
                >Go instead to Login</a
            >
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import router from '@/router/index';
import LscLoadingSpinner from '@/components/LscStructure/LscLoadingSpinner.vue';

export default {
    name: 'LscLoginPending',
    data() {
        return {
            globalStore: store.state,
            store: store,
            router: router,
            code: '',
            isOK: false
        };
    },
    components: { LscLoadingSpinner },
    mounted: function () {
        this.initKeycloak();
    },
    methods: {
        initKeycloak() {
            // console.log('init kc');

            let reloadCount = parseInt(localStorage.getItem('rlc'))
                ? parseInt(localStorage.getItem('rlc'))
                : 0;

            if (reloadCount > 0) {
                // delet local storage
                localStorage.removeItem('kc_token');
                localStorage.removeItem('kc_refreshToken');
                localStorage.removeItem('kc_refreshToken_expire');
                localStorage.setItem('rlc', 0);
            } else {
                localStorage.setItem('rlc', reloadCount + 1);
            }
            
            this.keycloak
                .init({ onLoad: this.globalStore.keycloakInitOptions.onLoad })
                .then((auth) => {
                    if (!auth) {
                        // todo what to do now?
                        console.log('Auth invalid');
                        window.location.reload();
                    } else {
                        //Vue.$log.info("Authenticated");
                        //console.log('Authenticated');

                        localStorage.setItem('kc_token', this.keycloak.token);
                        localStorage.setItem(
                            'kc_refreshToken',
                            this.keycloak.refreshToken
                        );
                        localStorage.setItem(
                            'kc_refreshToken_expire',
                            this.keycloak.refreshTokenParsed.exp
                        );

                        // TODO do some stuff

                        let redirect =
                            this.$router.currentRoute &&
                            this.$router.currentRoute._value &&
                            this.$router.currentRoute._value.query &&
                            this.$router.currentRoute._value.query.redirect
                                ? this.$router.currentRoute._value.query
                                      .redirect
                                : null;
                        this.$router.push({
                            path: redirect ? redirect : '/app/lsc-home'
                        });
                    }
                });
        }
    }
};
</script>

<style scoped>
.login-pending {
    background-color: var(--kds-color-acce2);
    height: 100%;
    color: var(--kds-color-atmo1);
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}

.login-pending h1, .login-pending p {
    color: var(--kds-color-atmo1);
}

.login-pending > div {
    max-width: 800px;
}

.logo-container {
    background-color: var(--kds-color-acce2);
    display: flex;
    align-items: center;
    justify-content: space-around;
}

img {
    width: 20%;
    padding: 2rem;
    border-radius: 10px;
}

.message-container {
    display: flex;
    align-items: center;
    justify-content: left;
}

.message-container.cnt {
    justify-content: space-evenly;
    margin-bottom: 6rem;
}

a.redirect {
    text-decoration: underline;
    cursor: pointer;
}

.spinner {
    margin: 3rem;
}
</style>
