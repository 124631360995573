const { dfRq, fileUploadRequest } = require('./endpointConnector');


function getNewsByNode(nodeId) {
    return dfRq({
        method: "get",
        urlPath: `/news/by-node/${nodeId}`
    }, {}, {}, true);
}

function getPublicNews() {
    return dfRq({
        method: "get",
        urlPath: `/pub/news`
    }, {}, {}, false);
}

function getPicturesFromServer() {
    return dfRq({
        method: "get",
        urlPath: `/news/pictures/all`
    }, {}, {}, true);
}

function createNews(currentNode, formData) {
    formData.link_to = currentNode;

    return dfRq({
        method: "put",
        urlPath: `/news/`
    }, {}, formData, true);
}

function editNews(formData) {
    return dfRq({
        method: "patch",
        urlPath: `/news/`
    }, {}, formData, true);
}

function deleteNews(newsId) {
    return dfRq({
        method: "delete",
        urlPath: `/news/${newsId}`
    }, {}, {}, true);
}


function uploadPicture(file, progEv) {
    return fileUploadRequest(`/news/upload`, file, progEv);
}

function rearrangeNewsByNode(objectData, activeNode) {

    console.log(objectData);

    const formData = {};
    formData.nodeNewsList = JSON.stringify(objectData);

    return dfRq({
        method: "post",
        urlPath: `/news/rearrange/${activeNode}`,
    }, {}, formData, true);
}

module.exports = {
    getNewsByNode,
    getPublicNews,
    getPicturesFromServer,
    createNews,
    editNews,
    deleteNews,
    uploadPicture,
    rearrangeNewsByNode
};