<template>
    <div class="lsc-news" v-bind:class="{ scroll: isScrolling }">
        <div class="news-image">
            <img :src="pictureUploadsUrl + newsInfo.main_picture" />
        </div>
        <div class="news-title kds-h1">{{ newsInfo.title }}</div>
        <div
            ref="textOutput"
            @scroll="scrollContent"
            class="news-content kds-paragraph"
        >
            <Markdown :source="replacedOutText" />
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import { pictureUploadsUrl } from '@/config/config';
import Markdown from 'vue3-markdown-it';

export default {
    components: {
        Markdown
    },
    data() {
        return {
            globalState: store.state,
            popupEditOpen: false,
            pictureUploadsUrl: '',
            replacedOutText: '',
            isScrolling: false
        };
    },
    name: 'LscNewsFullScreen',
    props: {
        newsInfo: Object
    },
    watch: {
        newsInfo: function () {
            this.replaceText();
        }
    },
    mounted: function () {
        this.pictureUploadsUrl = pictureUploadsUrl;
        this.replaceText();
    },
    methods: {
        replaceText() {
            this.replacedOutText = this.newsInfo.content
                .split('SERV_IMG_PATH')
                .join(pictureUploadsUrl);
        },
        scrollContent() {
            this.isScrolling =
                this.$refs.textOutput.scrollTop > 50 &&
                this.$refs.textOutput.scrollHeight > (window.innerHeight * 0.9)
                    ? true
                    : false;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lsc-news {
    height: 90vh;
    display: flex;
    flex-direction: column;
}

.lsc-news.scroll .news-image {
    height: 100px;
}

.news-image {
    height: 300px;
    transition: 0.2s;
}

.news-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-title {
    padding: 1rem 1rem;
}

.news-content {
    padding: 0 2rem 1rem 2rem;
    height: 100%;
    overflow-y: scroll;
}
</style>

<style>
.news-content > div p > img {
    max-width: 100%;
}
</style>

