<template>
    <div class="frontpage" v-bind:class="{
                        'no-news': newsList.length == 0
                    }">
        <div class="fp-news"  v-bind:class="{
                        'no-news': newsList.length == 0
                    }">
            <LscNewsFullScreen v-if="newsList.length > 0" v-bind:newsInfo="currentNewsItem ? currentNewsItem : {}" />
        </div>
        <div class="fp-login">
            <div class="logo-container" id="logo-container">
                <img
                    alt="Vue logo"
                    src="@/assets/koerber_logo_rgb_white.0763d983.svg"
                />
            </div>
            <LscLogin screenType="small" />
        </div>
        <div class="fp-news-nav" v-bind:class="{
                        'no-news': newsList.length == 0
                    }">
            <kds-icon
                name="chevron-left"
                @click="displayNewsList(-1)"
            ></kds-icon>

            <div v-for="(news, index) in newsList" :key="news.id">
                <div
                    v-bind:class="{
                        active: index === currentActiveNewsItemIndex
                    }"
                    class="pos"
                    :index="index"
                ></div>
            </div>

            <kds-icon
                name="chevron-right"
                @click="displayNewsList(1)"
            ></kds-icon>
        </div>
        <div class="fp-options">
            <a href="https://www.koerber.com/en/privacy-policy">Privacy</a>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import LscLogin from '@/components/LscStructure/LscLogin.vue';
import newsHandler from '@/lib/newsHandler';
import LscNewsFullScreen from '@/components/LscNews/LscNewsFullScreen.vue';

export default {
    name: 'LscFrontpage',
    data() {
        return {
            newsList: [],
            currentNewsItem: {},
            currentActiveNewsItemIndex: 0
        };
    },
    components: {
        LscLogin,
        LscNewsFullScreen
    },
    mounted: function () {
        setTimeout(() => this.animateLogo(), 300);
        this.loadNews();
    },
    methods: {
        animateLogo() {
            document.querySelector('#logo-container').classList.add('active');
        },

        loadNews() {
            newsHandler
                .getPublicNews()
                .then((response) => {
                    console.log(response.data);
                    this.newsList = response.data;
                    this.displayNewsList();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        displayNewsList(nextItemStep) {
            if (nextItemStep !== undefined) {
                this.currentActiveNewsItemIndex =
                    (this.currentActiveNewsItemIndex + nextItemStep) %
                    this.newsList.length;
            }
            this.currentNewsItem =
                this.newsList[this.currentActiveNewsItemIndex];
        }
    }
};
</script>

<style scoped>
.frontpage {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'fp-news fp-login'
        'fp-news-nav fp-options';
    height: 100%;
}

.frontpage.no-news {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'fp-login'
        'fp-options';
    height: 100%;
}

.fp-news-nav.no-news, .fp-news.no-news {
    display: none;
}

.fp-news {
    grid-area: fp-news;
}

.fp-login {
    grid-area: fp-login;
    background-color: var(--kds-color-acce2);
}

.fp-news-nav {
    grid-area: fp-news-nav;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    --kds-icon-color: var(--kds-color-acce2);
}

.fp-news-nav .pos {
    height: 5px;
    width: 5px;
    display: block;
    margin: 10px;
    border-radius: 50%;
    border: 2px solid var(--kds-color-atmo6);
}

.fp-news-nav .pos.active {
    background-color: var(--kds-color-atmo6);
}

.fp-options {
    grid-area: fp-options;
    background-color: var(--kds-color-acce2);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.fp-options a {
    grid-area: fp-options;
    color: var(--kds-color-atmo1);
    margin: 1rem;
    cursor: pointer;
}

.fp-options a:hover {
    text-decoration: underline;
}

.login {
    background-color: var(--kds-color-acce2);
    height: 100%;
}

.logo-container {
    background-color: var(--kds-color-acce2);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: 1s;
}

.logo-container.active {
    height: 30%;
}

img {
    width: 20%;
    padding: 2rem;
    border-radius: 10px;
}

kds-icon {
    cursor: pointer;
}

@media only screen and (max-device-width: 600px) {
    .frontpage {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr min-content;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
            'fp-login'
            'fp-options';
        height: 100%;
    }

    .fp-news-nav {
        display: none;
    }

    .fp-news {
        display: none;
    }
}
</style>
